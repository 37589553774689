<template>
  <div>
    <!--Progress Bar-->
    <v-dialog v-model="progress" max-width="290" persistent hide-overlay>
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          Loading
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container v-if="!progress" fluid fill-height grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat dense dark color="secondary">
            <v-icon>fal fa-tag</v-icon>
            <v-toolbar-title class="pl-2">
              Invoice Details
            </v-toolbar-title>
          </v-toolbar>

          <v-card flat color="accent">
            <v-card-text class="px-0">
              <v-container fluid grid-list-md class="pt-0 px-0">
                <!--Invoice Details-->
                <v-layout row wrap>
                  <v-flex sx12>
                    <v-card flat height="100%">
                      <v-card-text>
                        <!--Company Name-->
                        <v-layout
                          v-if="usersStore.authUser.customers.length > 0"
                          row
                          wrap
                        >
                          <v-flex xs12 class="py-0">
                            <div
                              class="subtitle-1 grey--text text--darken-3 font-weight-bold"
                            >
                              {{ invoicesStore.invoice.customer.d_companyName }}
                            </div>
                          </v-flex>
                        </v-layout>

                        <v-layout row wrap class="pt-4">
                          <v-flex xs6 md2>
                            <v-text-field
                              :value="invoicesStore.invoice.d_invoiceNumber"
                              label="Invoice Number"
                              placeholder=" "
                              persistent-placeholder
                              flat
                              readonly
                              loading="false"
                              class="pb-0"
                            />
                          </v-flex>
                          <v-flex xs6 md2>
                            <v-text-field
                              :value="formatDate(invoicesStore.invoice.d_date)"
                              label="Invoice Date"
                              placeholder=" "
                              persistent-placeholder
                              flat
                              readonly
                              loading="false"
                              class="pb-0"
                            />
                          </v-flex>
                          <v-flex xs6 md2>
                            <v-text-field
                              :value="
                                formatDate(invoicesStore.invoice.d_dateDue)
                              "
                              label="Due Date"
                              placeholder=" "
                              persistent-placeholder
                              flat
                              readonly
                              loading="false"
                              class="pb-0"
                            />
                          </v-flex>
                          <v-flex
                            v-if="usersStore.authUser.f_primary === 1"
                            xs6
                            md2
                          >
                            <v-text-field
                              :value="
                                translateInvoiceStatus(
                                  invoicesStore.invoice.f_status
                                )
                              "
                              label="Status"
                              placeholder=" "
                              persistent-placeholder
                              flat
                              readonly
                              loading="false"
                              class="pb-0"
                            />
                          </v-flex>
                        </v-layout>

                        <v-layout row wrap>
                          <v-flex xs6 md2>
                            <v-text-field
                              :value="invoicesStore.invoice.order.d_orderNumber"
                              label="Order ID"
                              placeholder=" "
                              persistent-placeholder
                              flat
                              readonly
                              loading="false"
                              class="pb-0"
                            />
                          </v-flex>
                          <v-flex xs6 md2>
                            <v-text-field
                              :value="
                                invoicesStore.invoice.order
                                  .d_customerPurchaseOrder
                              "
                              label="PO Number"
                              placeholder=" "
                              persistent-placeholder
                              flat
                              readonly
                              loading="false"
                              class="pb-0"
                            />
                          </v-flex>
                          <v-flex xs6 md4>
                            <v-text-field
                              :value="invoicesStore.invoice.order.d_jobName"
                              label="Job Name"
                              placeholder=" "
                              persistent-placeholder
                              flat
                              readonly
                              loading="false"
                              class="pb-0"
                            />
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>

                <!--  Line Items -->
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-list
                      v-if="invoicesStore.invoice.j_line_items.length > 0"
                      two-line
                      class="pt-0"
                    >
                      <template
                        v-for="(item, i) in invoicesStore.invoice.j_line_items"
                      >
                        <v-list-item :key="i">
                          <v-list-item-content>
                            <v-list-item-title class="subtitle-1">
                              {{ item.d_item }}
                              - {{ item.d_quantity }} x
                              {{ formatMoney(item.d_rate) }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="subtitle-1">
                              {{ item.d_description }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-action-text
                              class="subtitle-1 black--text text-right"
                            >
                              {{ formatMoney(item.d_amount) }}
                            </v-list-item-action-text>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider />
                      </template>
                    </v-list>

                    <v-list v-else>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="red--text subtitle-1 text-xs-center"
                          >
                            No Line Items Exist
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-flex>
                </v-layout>

                <!-- Totals -->
                <v-layout row wrap>
                  <!--Invoice Details-->
                  <v-flex sx12>
                    <v-card flat height="100%">
                      <v-card-text>
                        <v-layout row wrap>
                          <v-flex xs12 md4 offset-md8 text-right>
                            <span class="subtitle-1">Subtotal : </span
                            ><span
                              class="subtitle-1 font-weight-bold black--text"
                              >{{
                                formatMoney(
                                  invoicesStore.invoice.d_amountSubtotal
                                )
                              }}</span
                            >
                          </v-flex>
                        </v-layout>
                        <v-layout
                          v-if="invoicesStore.invoice.d_amountDiscount > 0"
                          row
                          wrap
                        >
                          <v-flex xs12 md4 offset-md8 text-right>
                            <span class="subtitle-1">Discount : </span
                            ><span
                              class="subtitle-1 font-weight-bold black--text"
                              >{{
                                formatMoney(
                                  invoicesStore.invoice.d_amountDiscount
                                )
                              }}</span
                            >
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                          <v-flex xs12 md4 offset-md8 text-right>
                            <span class="subtitle-1">Sales Tax : </span
                            ><span
                              class="subtitle-1 font-weight-bold black--text"
                              >{{
                                formatMoney(invoicesStore.invoice.d_amountTax)
                              }}</span
                            >
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                          <v-flex xs12 md4 offset-md8 text-right>
                            <span class="subtitle-1">Total : </span
                            ><span
                              class="subtitle-1 font-weight-bold secondary--text"
                              >{{
                                formatMoney(invoicesStore.invoice.d_amountTotal)
                              }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>

                <!-- Payments -->
                <v-layout
                  v-if="invoicesStore.invoice.payments.length > 0"
                  row
                  wrap
                >
                  <v-flex xs12>
                    <v-toolbar flat>
                      <v-toolbar-title class="primary--text">
                        Payments
                      </v-toolbar-title>
                    </v-toolbar>
                    <!-- Payments List -->
                    <v-list three-line class="pt-0">
                      <template
                        v-for="(item, i) in invoicesStore.invoice.payments"
                      >
                        <v-list-item :key="i" @click="goPayment(item.uuid)">
                          <v-list-item-avatar v-if="item.payment_method">
                            <v-icon color="primary" medium>
                              {{ item.payment_method.d_icon }}
                            </v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.d_paymentNumber }} -
                              {{ formatDate(item.d_date) }}
                            </v-list-item-title>
                            <v-list-item-title class="text--primary">
                              ${{ item.d_totalAmount }}
                            </v-list-item-title>
                            <v-list-item-title class="text--primary">
                              {{ item.payment_method.d_name
                              }}<span v-if="item.payment_profile">
                                - {{ item.payment_profile.d_ccLast4 }}</span
                              >
                            </v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-list-item-action-text
                              :class="
                                'text-xs-right subtitle-1 ' +
                                  [
                                    item.f_status === 1
                                      ? 'green--text lighten-1--text'
                                      : 'error--text'
                                  ]
                              "
                            >
                              {{ translatePaymentStatus(item.f_status) }}
                            </v-list-item-action-text>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider />
                      </template>
                    </v-list>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "@/mixins/mixin.js";

export default {
  name: "InvoiceDetail",
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      invoicesStore: state => state.invoicesStore
    })
  },
  data() {
    return {
      progress: true
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      const user = this.$store.getters["usersStore/getAuthUser"];

      if (!user) {
        this.$router.push({ name: "logout" });
      } else {
        const uuid = this.$route.params.invoiceUUID;
        await this.$store.dispatch("invoicesStore/readUUID", uuid);
        this.progress = false;
      }
    },

    lineItemTotal(xs, sm, md, lg, xl, xxl, xxxl, xxxxl, xxxxxl, osfa, other) {
      const xs2 = xs || 0;
      const sm2 = sm || 0;
      const md2 = md || 0;
      const lg2 = lg || 0;
      const xl2 = xl || 0;
      const xxl2 = xxl || 0;
      const xxxl2 = xxxl || 0;
      const xxxxl2 = xxxxl || 0;
      const xxxxxl2 = xxxxxl || 0;
      const osfa2 = osfa || 0;
      const other2 = other || 0;

      let itemTotal =
        Number(xs2) +
        Number(sm2) +
        Number(md2) +
        Number(lg2) +
        Number(xl2) +
        Number(xxl2) +
        Number(xxxl2) +
        Number(xxxxl2) +
        Number(xxxxxl2) +
        Number(osfa2) +
        Number(other2);
      itemTotal = isNaN(itemTotal) ? 0 : itemTotal;

      return itemTotal;
    },

    goPayment(uuid) {
      this.$router.push({
        name: "paymentDetail",
        params: { paymentUUID: uuid }
      });
    }
  }
};
</script>

<style scoped>
.lineItemContainer {
  height: 65%;
}
button {
  outline: none;
}
</style>
